import React from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  FormControl,
  MenuItem,
} from '@mui/material';

const WhitelistFilter = ({ filter, setFilter }) => (
  <FormControl variant="outlined">
    <Select
      name="bank"
      value={filter}
      onChange={(event) => setFilter(event.target.value)}
    >
      <MenuItem value="all">Todos</MenuItem>
      <MenuItem value="active">Activos</MenuItem>
      <MenuItem value="inactive">Inactivos</MenuItem>
    </Select>
  </FormControl>
);

WhitelistFilter.propTypes = {
  filter: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default WhitelistFilter;
