import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import MuiLink from '@mui/material/Link';
import { formatDayMonthYearSlash, formatMoney } from '@fingo/lib/helpers';

const summaryHeaders = [
  [
    { label: 'Monto:', getValue: (invoice) => `$${formatMoney(invoice.amountWithIva)}` },
    {
      label: 'Emisor:',
      getValue: (invoice) => `${invoice.company.masterEntity.name}\n${invoice.company.masterEntity.displayNationalIdentifier}`,
    },
    {
      label: 'Deudor:',
      getValue: (invoice) => `${invoice.receiver.name}\n${invoice.receiver.displayNationalIdentifier}`,
    },
  ],
  [
    {
      label: 'Fecha emisión:',
      getValue: (invoice) => `${formatDayMonthYearSlash(invoice.dateIssued)}`,
      lightHeader: true,
    },
    {
      label: 'Fecha recepción SII:',
      getValue: (invoice) => {
        if (invoice.receptionDate) {
          return `${formatDayMonthYearSlash(invoice.receptionDate)}`;
        }
        return `${formatDayMonthYearSlash(invoice.dateIssued)}`;
      },
    },
    { label: 'Pago:', getValue: (invoice) => invoice.paymentMethod },
    {
      label: 'Comentarios último estado:',
      getValue: (invoice) => (invoice.uniqueDocumentFinanceState.length
        ? invoice.uniqueDocumentFinanceState[0]?.comments
        : 'Sin Comentarios'),
    },
  ],
];

const reduceNotesAmount = (notes, label) => notes?.reduce((sum, cr) => sum + cr[label], 0);

const retrieveDiscountLabel = (invoice) => {
  const debitAmount = reduceNotesAmount(invoice.debitNotes, 'amountWithIva');
  const creditAmount = reduceNotesAmount(invoice.creditNotes, 'amountWithIva');
  if (debitAmount > creditAmount) return 'Débito';
  if (debitAmount < creditAmount) return 'Crédito';
  return 'Sin Descuentos';
};

const summaryLucilaHeaders = [
  [
    {
      label: 'Tasa Interés:',
      getValue: (invoice) => `${invoice.offer?.monthlyRate ?? invoice.preoffer?.monthlyRate}%`,
      lightHeader: true,
    },
    {
      label: 'Comisión:',
      getValue: (invoice) => (invoice.operation ? `$${formatMoney(invoice.operation.commission)}` : 'Sin operación'),
    },
    {
      label: 'Descuentos:',
      getValue: (invoice) => retrieveDiscountLabel(invoice),
    },
    {
      label: 'Dctos Ordering:',
      getValue: (invoice) => (`$${formatMoney(
        reduceNotesAmount(invoice.orderingAssigned, 'amount'),
      )}`),
    },
  ],
];

const referencesHeaders = [
  [
    {
      label: '',
      getValue: (reference) => {
        const referenceInvoice = reference.toInvoice || reference.fromInvoice;
        const folioMessage = referenceInvoice.folio.length > 10 ? `${referenceInvoice.folio.substring(0, 10)}...` : referenceInvoice.folio;
        return (
          <Typography variant="body2">
            {referenceInvoice.dteType.name}{' '}
            <Link
              component={MuiLink}
              underline="hover"
              to={{
                pathname: '/app/invoice-profile',
                search: `?invoiceId=${referenceInvoice.id}`,
              }}
              color="primary"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                '&:hover': {
                  color: (theme) => `${theme.palette.primary.main} !important`,
                },
              }}
            >
              {`${folioMessage}`}
            </Link>
            <Typography variant="body2">${formatMoney(reference?.amount)}</Typography>
          </Typography>
        );
      },
    },
  ],
];

export { summaryHeaders, referencesHeaders, summaryLucilaHeaders };
