import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import MessageContent from './MessageContent';

const ChatContent = ({ whatsappLogs }) => (
  <Box
    sx={{
      height: '100%',
      boxShadow: 'none',
      overflowY: 'scroll',
      display: 'flex',
      flexDirection: 'column-reverse',
      borderRadius: 0,
      backgroundColor: '#FFF',
    }}
  >
    {whatsappLogs?.map(
      ({ id, inbox, ...rest }) => <MessageContent key={id} message={rest} inbox={inbox} />,
    )}
  </Box>
);
ChatContent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  whatsappLogs: PropTypes.array.isRequired,
  pageInfo: PropTypes.shape({ hasNextPage: PropTypes.bool.isRequired }),
};

ChatContent.defaultProps = {
  pageInfo: null,
};

export default ChatContent;
