import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Circle from '@mui/icons-material/Circle';

const RelatedUserInformation = ({ conversation }) => {
  if (!conversation.relatedUser) return null;
  const AuxInfoTooltipNode = (
    <Stack direction="column" spacing={0.5}>
      <Typography>
        Número: {conversation.fromPhoneNumber}
      </Typography>
      {conversation.relatedUser?.usercompanySet?.map((company) => (
        <>
          <Divider />
          <Typography>
            {`${company.company.masterEntity.name} 
            ${company.company.masterEntity.displayNationalIdentifier}`}
          </Typography>
          <Typography>
            {`Ejecutivo Asignado: ${company.company.executiveAssigned?.firstName ?? ''}
            ${company.company.executiveAssigned?.lastName ?? ''}`}
          </Typography>
        </>
      ))}
    </Stack>
  );
  return (
    <Tooltip title={AuxInfoTooltipNode}>
      <Circle sx={{ width: '15px', color: 'primary.main' }} />
    </Tooltip>
  );
};

RelatedUserInformation.propTypes = {
  conversation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fromPhoneNumber: PropTypes.string.isRequired,
    lastMessageDatetime: PropTypes.string.isRequired,
    relatedUser: PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      usercompanySet: PropTypes.array,
    }),
  }).isRequired,
};

export default RelatedUserInformation;
