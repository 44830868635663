import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { WHATSAPP_CONVERSATION, NEW_WHATSAPP_MESSAGE } from '@fingo/lib/graphql';
import { useQuery } from '@apollo/client';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ChatContent from './ChatContent';
import BottomBarContent from './BottomBarContent';
import TopBarContent from './TopBarContent';
import LoadingSidebar from '../Sidebar/LoadingSidebar';

const ChatView = ({ hashId, twilioId }) => {
  const [page, setPage] = useState(0);
  const { data, subscribeToMore, loading } = useQuery(WHATSAPP_CONVERSATION, {
    variables: {
      first: 50,
      offset: page * 50,
      orderBy: '-pk',
      conversationHash: hashId,
      twilioSenderId: twilioId,
    },
    skip: !hashId || !twilioId,
  });
  const concatOrMergeNewLog = (newLog, array) => {
    const index = array.findIndex((element) => (element.node.id === newLog.node.id));
    if (index === -1) return [newLog, ...array];
    const array1 = array.slice(0, index);
    const array2 = array.slice(index + 1);
    return [...array1, ...[newLog], ...array2];
  };

  useEffect(() => {
    if (hashId && twilioId) {
      setPage(0);
    }
  }, [hashId, twilioId]);

  useEffect(() => subscribeToMore({
    document: NEW_WHATSAPP_MESSAGE,
    variables: { conversationHash: hashId, twilioSenderId: twilioId },
    skip: !hashId || !twilioId,
    updateQuery: (prev, { subscriptionData }) => {
      if (!subscriptionData.data) return prev;
      const node = subscriptionData.data.newWhatsappMessage.whatsappLog;
      const newLog = { __typename: 'WhatsappLogTypeEdge', node };
      const newConv = {
        ...prev,
        whatsappConversation: {
          ...prev.whatsappConversation,
          lastMessage: node,
          lastMessageDatetime: node.createdAt,
          whatsapplogSet: {
            ...prev.whatsappConversation.whatsapplogSet,
            edges: concatOrMergeNewLog(newLog, prev.whatsappConversation.whatsapplogSet.edges),
          },
        },
      };
      return newConv;
    },
  }), [hashId, twilioId]);

  const conversation = data?.whatsappConversation ?? null;
  const logs = conversation?.whatsapplogSet?.edges?.map((edge) => edge.node) ?? [];
  const pageInfo = data?.whatsappConversation?.whatsapplogSet?.pageInfo;

  if (loading && !conversation) return <LoadingSidebar numberRows={16} />;
  return (
    <Stack sx={{ height: '100%' }}>
      <TopBarContent conversation={conversation} />
      <Stack direction="row" spacing={1} alignSelf="center">
        <IconButton onClick={() => setPage(page + 1)} disabled={!pageInfo?.hasNextPage}>
          <KeyboardArrowLeftIcon />
        </IconButton>
        <IconButton disabled={page === 0} onClick={() => setPage(page - 1)}>
          <KeyboardArrowRightIcon />
        </IconButton>
      </Stack>
      <ChatContent whatsappLogs={logs} />
      <BottomBarContent
        conversationHashId={hashId}
        twilioSenderId={twilioId}
      />
    </Stack>
  );
};

ChatView.propTypes = {
  hashId: PropTypes.string.isRequired,
  twilioId: PropTypes.string.isRequired,
};

export default ChatView;
