/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';

const WhatsappVideoMessage = ({ file }) => (
  <Stack sx={{ width: { xs: '50px', md: '300px' } }}>
    <video width="100%" controls src={file.file.url} />
  </Stack>
);

WhatsappVideoMessage.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.string.isRequired,
    file: PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default WhatsappVideoMessage;
