import React, { useCallback, useMemo, useState } from 'react';
import { PaperHeader } from '@fingo/lib/components/headers';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useTextFieldValue } from '@fingo/lib/hooks';
import { useInvoicePreColumns } from '@fingo/lib/constants';
import { FormDataGrid } from '@fingo/lib/components/dataGrids';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { UPDATE_SIMULATION_INVOICE } from '@fingo/lib/graphql';
import CreateSimulationHeader from './headers/CreateSimulationHeader';
import useCreateInvoiceSimulation from '../../../../hooks/useCreateInvoiceSimulation';
import SimulationSummary from './summary/SimulationSummary';

const CreateSimulation = () => {
  const [client, setClient] = useState();
  const [debtor, setDebtor] = useState();
  const [amount, setAmount] = useState(0);
  const [days, setDays] = useTextFieldValue();
  const [_invoices, setInvoices] = useState([]);
  const ids = [
    'textFolio',
    'company_MasterEntity_Name',
    'receiver_Name_Loaned',
    'amountWithIva',
    'paymentDays',
    'rawPreofferMonthlyRate',
  ];
  const editableRows = [
    'amountWithIva',
    'paymentDays',
    'rawPreofferMonthlyRate',
  ];
  const columns = useInvoicePreColumns().filter(
    ({ field }) => ids.includes(field),
  ).map((column) => ({
    ...column,
    sortable: false,
    editable: editableRows.includes(column.field),
  }));
  const [createNewInvoice, loadingNewInvoice] = useCreateInvoiceSimulation(
    client,
    debtor,
    amount,
    days,
    setInvoices,
  );
  const [_updateSimulationInvoice] = useMutation(UPDATE_SIMULATION_INVOICE);
  const cleanSimulation = useCallback(() => {
    setInvoices([]);
  }, []);
  const invoices = useMemo(() => _invoices.map((inv) => ({
    ...inv,
    dateIssued: moment(inv.dateIssued),
    dateToPay: moment(inv.dateToPay),
  })), [_invoices]);
  const updateSimulationInvoice = useCallback((newRow) => _updateSimulationInvoice({
    variables: {
      updateInvoice: {
        invoiceId: newRow.id,
        daysToPay: 60,
        amountWithIva: {
          amount: newRow.amountWithIva.amount,
          currency: newRow.amountWithIva.currency.code,
        },
        monthlyRate: newRow.preoffer.monthlyRate,
      },
    },
  }), []);
  return (
    <Box p={4}>
      <PaperHeader viewTitle="Simulación" />
      <CreateSimulationHeader
        setClient={setClient}
        setDebtor={setDebtor}
        setAmount={setAmount}
        amount={amount}
        setDays={setDays}
        onAdd={createNewInvoice}
        onClean={cleanSimulation}
        loading={loadingNewInvoice}
        invoices={invoices}
      />
      <Grid container columnSpacing={2} mt={4}>
        <Grid item container xs={8}>
          <FormDataGrid
            isBackoffice
            rows={invoices}
            setRows={setInvoices}
            originalRows={invoices}
            columns={columns}
            showToolbar={false}
            onUpdate={updateSimulationInvoice}
            hideFooter
          />
        </Grid>
        <Grid item xs={4} container>
          <Box width="100%">
            <Paper sx={{ width: '100%', p: 5 }}>
              <SimulationSummary invoices={invoices} />
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateSimulation;
