import { useBooleanState } from '@fingo/lib/hooks';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import PropTypes from 'prop-types';

const EditableText = ({ initialValue, setValue, formatedValue, reset }) => {
  const [editing, toggleEditing] = useBooleanState();
  if (editing) {
    return (
      <Stack direction="row" alignItems="center">
        <TextField
          fullWidth
          initialValue={initialValue}
          onChange={setValue}
          variant="standard"
        />
        <IconButton size="small" onClick={toggleEditing}>
          <SaveIcon fontSize="small" />
        </IconButton>
      </Stack>
    );
  }
  return (
    <Stack direction="row" alignItems="center">
      <Typography>{formatedValue}</Typography>
      <IconButton size="small" onClick={toggleEditing}>
        <EditIcon fontSize="small" />
      </IconButton>
      <IconButton size="small" onClick={reset}>
        <CancelIcon fontSize="small" />
      </IconButton>
    </Stack>
  );
};

EditableText.propTypes = {
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setValue: PropTypes.func.isRequired,
  formatedValue: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired,
};

export default EditableText;
