import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { FACTORING_SIMULATIONS } from '@fingo/lib/graphql';
import React from 'react';
import useSimulationColumns from '../../../../columns/useSimulationColumns';

const ViewSimulation = () => {
  const columns = useSimulationColumns();
  return (
    <FingoMainView
      id="simulations"
      query={FACTORING_SIMULATIONS}
      slots={{
        header: PaperHeader,
        table: FingoDataGrid,
      }}
      slotProps={{
        header: {
          viewTitle: 'Simulaciones',
          finder: {
            searchPlaceHolder: 'Buscar',
          },
        },
        table: {
          columns,
          // initialOrderBy: '-id',
          keepNonExistentRowsSelected: true,
          rowsPerPageOptions: [10, 25, 50, 100],
        },
      }}
    />
  );
};

export default ViewSimulation;
