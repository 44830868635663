import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { formatDateTime } from '@fingo/lib/helpers/date';
import moment from 'moment';
import RelatedUserInformation from './RelatedUserInformation';

const TopBarContent = ({ conversation }) => (
  <Box p={1.5}>
    <Stack direction="row" spacing={1.5} alignItems="center">
      <Avatar />
      <Stack alignItems="flex-start">
        <Typography variant="h5">
          {!conversation.relatedUser ? `${conversation.fromPhoneNumber}` : `${conversation.relatedUser.firstName} ${conversation.relatedUser.lastName}`}
        </Typography>
        <Typography>
          Último Mensaje: {formatDateTime(moment(conversation.lastMessageDatetime))}
        </Typography>
      </Stack>
      <RelatedUserInformation conversation={conversation} />
    </Stack>
  </Box>
);

TopBarContent.propTypes = {
  conversation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fromPhoneNumber: PropTypes.string.isRequired,
    lastMessageDatetime: PropTypes.string.isRequired,
    relatedUser: PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      usercompanySet: PropTypes.array,
    }),
  }).isRequired,
};

export default TopBarContent;
