import React, { useState } from 'react';
import FingoTable from '@fingo/lib/components/tables/FingoTable';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { whitelistDefaultColumns } from '@fingo/lib/constants';
import { MASTER_ENTITIES_WHITELIST } from '@fingo/lib/graphql';
import Typography from '@mui/material/Typography';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import WhitelistDetails from './WhitelistDetails';
import WhitelistActions from './WhitelistActions';

const Whitelist = () => {
  const [filter, setFilter] = useState('active');
  const country = useGetCountryFromUrl();
  return (
    <FingoMainView
      id="customers-manager"
      query={MASTER_ENTITIES_WHITELIST}
      queryCustomVariables={{
        filterByRateWhitelist: filter,
        countryId: country?.id,
      }}
      slots={{
        header: PaperHeader,
        table: FingoTable,
        actions: WhitelistActions,
      }}
      slotProps={{
        header: {
          viewTitle: 'Reglas de tasas preferenciales',
          finder: {
            searchPlaceHolder: 'Buscar cliente',
          },
        },
        table: {
          columns: whitelistDefaultColumns,
          noRowsMessage: () => <Typography>No hay clientes con whitelist</Typography>,
          initialOrderBy: 'name',
          includeHeaders: [
            'company',
          ],
          collapsible: true,
          isRowCollapsible: () => true,
          collapseComponent: ({ row }) => (
            <WhitelistDetails filter={filter} masterEntityId={row?.id} />
          ),
          rowsPerPageOptions: [50, 100],
        },
        actions: {
          filter,
          setFilter,
        },
      }}
    />
  );
};

export default Whitelist;
