import React from 'react';
import BooleanCell from '@fingo/lib/components/cells/BooleanCell';
import { toPercentage } from '@fingo/lib/helpers';

export const DATA_GRID_COLUMNS = [
  {
    field: 'user_Name',
    type: 'string',
    headerName: 'Ejecutivo',
    valueGetter: ({ row }) => `${row?.user?.firstName || ''} ${row?.user?.lastName || ''}`,
    flex: 2,
  },
  {
    field: 'active',
    type: 'boolean',
    headerName: 'Ejecutivo Activo',
    renderCell: ({ row }) => <BooleanCell value={row.active} />,
    flex: 2,
  },
  {
    field: 'tycActiveAssignation',
    type: 'boolean',
    headerName: 'Asignación en TyC',
    renderCell: ({ row }) => <BooleanCell value={row.tycActiveAssignation} />,
    flex: 2,
  },
  {
    field: 'loginActiveAssignation',
    type: 'boolean',
    headerName: 'Asignación en Login',
    renderCell: ({ row }) => <BooleanCell value={row.loginActiveAssignation} />,
    flex: 2,
  },
  {
    field: 'clickActiveAssignation',
    type: 'boolean',
    headerName: 'Asignación en Click',
    renderCell: ({ row }) => <BooleanCell value={row.clickActiveAssignation} />,
    flex: 2,
  },
  {
    field: 'monthlyAssignations',
    headerName: 'Asignaciones mensuales',
    valueFormatter: ({ value }) => value || 'n/a',
    flex: 2,
  },
  {
    field: 'weeklyAssignations',
    headerName: 'Asignaciones semanales',
    valueFormatter: ({ value }) => value || 'n/a',
    flex: 2,
  },
  {
    field: 'hubspotId',
    headerName: 'ID de Hubspot',
    valueFormatter: ({ value }) => value || 'No tiene ID',
    flex: 2,
  },
  {
    field: 'clickAssignationProportion',
    headerName: 'Proporcion de asignación (Click)',
    valueFormatter: ({ value }) => toPercentage(value),
    flex: 3,
  },
  {
    field: 'loginAssignationProportion',
    headerName: 'Proporcion de asignación (Login)',
    valueFormatter: ({ value }) => toPercentage(value),
    flex: 3,
  },
];

export const HEADER_TITLE = 'Ejecutivos';
