import React from 'react';
import PropTypes from 'prop-types';
import { AddOrEditWhitelistCell } from '@fingo/lib/components/cells';
import WhitelistFilter from './WhitelistFilter';

const WhitelistActions = ({ filter, setFilter }) => (
  <>
    <WhitelistFilter filter={filter} setFilter={setFilter} />
    <AddOrEditWhitelistCell />
  </>

);

WhitelistActions.propTypes = {
  filter: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default WhitelistActions;
