/* eslint-disable import/prefer-default-export */
import React from 'react';

import { GenerateContractButton } from '@fingo/lib/components/contractGeneration';
import { CustomerDocumentsCell, CompanyProfileCell } from '@fingo/lib/components/cells';
import UploadToExternalServiceButton from '../components/contracts-manager/actions/UploadToExternalServiceButton';
import {
  ContractStatus,
  ContractType,
} from '../components/contracts-manager';

export const CONTRACT_MANAGER_COLUMNS = [
  {
    field: 'company_formation_type',
    type: 'string',
    headerName: 'Tipo de contrato',
    renderCell: ({ row }) => (
      <ContractType value={row?.companyContractType} />
    ),
    sortable: true,
    flex: 1,
  },
  {
    field: 'contract_status',
    type: 'string',
    headerName: 'Estado de contrato',
    renderCell: ({ row }) => (
      <ContractStatus value={row?.status} />
    ),
    sortable: true,
    flex: 1,
  },
  {
    field: 'contract_documents',
    headerName: 'Documentos',
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <CustomerDocumentsCell masterEntity={row} wrap initialDocumentTypes={['legal_report', 'cte', 'contract']} />
    ),
    flex: 2,
  },
  {
    field: 'contract_generation',
    type: 'string',
    headerName: 'Generar contrato',
    renderCell: ({ row }) => (
      <GenerateContractButton
        companyId={row.company.id}
        contractRequestStatus={row?.status}
      />
    ),
    sortable: true,
    flex: 1,
  },
  {
    field: 'upload_external_contract_service',
    type: 'string',
    headerName: 'Subir a Firma Ya',
    renderCell: ({ row }) => (
      <UploadToExternalServiceButton
        companyId={row.company.id}
        contractRequestStatus={row?.status}
      />
    ),
    sortable: true,
    flex: 1,
  },
  {
    field: 'nameWithProfile',
    type: 'string',
    headerName: 'Empresa',
    renderCell: ({ row }) => <CompanyProfileCell masterEntity={row.company.masterEntity} />,
    sortable: true,
    flex: 2,
  },
];

export const CONTRACT_MASTER_ENTITY_COLUMNS = [
  {
    field: 'contract_documents',
    headerName: 'Documentos',
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <CustomerDocumentsCell masterEntity={row} wrap initialDocumentTypes={['legal_report', 'cte', 'contract']} />
    ),
    flex: 2,
  },
];
