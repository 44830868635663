import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import ExecutiveFilter from './ExecutiveFilter';
import MarketingCampaignFilter from './MarketingCampaignFilter';

const HeaderView = ({ filters, updateFilters }) => (
  <Box p={1}>
    <Stack direction="row" spacing={1}>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          sx: { borderRadius: '10px', height: '35px' },
        }}
        sx={{ width: '78%' }}
        placeholder="Buscar conversación o mensaje"
        name="globalFilter"
        value={filters.globalFilter}
        onChange={(e) => updateFilters('globalFilter', e.target.value)}
      />
      <ExecutiveFilter filters={filters} updateFilters={updateFilters} />
      <MarketingCampaignFilter filters={filters} updateFilters={updateFilters} />
    </Stack>
  </Box>
);

HeaderView.propTypes = {
  filters: PropTypes.shape({
    globalFilter: PropTypes.string,
  }).isRequired,
  updateFilters: PropTypes.func.isRequired,
};

export default HeaderView;
