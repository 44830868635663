import React from 'react';

import {
  Box,
  Stack,
  List,
  IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import HeaderView from './HeaderView';
import ConversationItem from './ConversationItem';
import LoadingSidebar from './LoadingSidebar';

const SidebarView = (
  {
    page,
    setPage,
    conversations,
    selectedConversation,
    setSelectedConversation,
    filters,
    setFilters,
    loading,
  },
) => {
  const updateFilters = (label, value) => {
    setFilters({
      ...filters,
      [label]: value,
    });
  };
  return (
    <Stack>
      <HeaderView filters={filters} updateFilters={updateFilters} />
      <Stack direction="row" spacing={1} alignSelf="center">
        <IconButton
          disabled={page === 0}
          onClick={() => setPage(page - 1)}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
        <IconButton
          disabled={conversations.length < 15}
          onClick={() => setPage(page + 1)}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Stack>
      {loading ? <LoadingSidebar numberRows={14} /> : (
        <Box sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 180px)' }}>
          <List disablePadding component="div">
            {conversations.map(
              ({ node }) => (
                <ConversationItem
                  key={`conversation-${node.conversationHash}-${node.twilioSender.id}`}
                  conversation={node}
                  setSelectedConversation={setSelectedConversation}
                  selected={
                    node.conversationHash === selectedConversation.hashId
                    && node.twilioSender.id === selectedConversation.twilioId
                  }
                />
              ),
            )}
          </List>
        </Box>
      )}
    </Stack>
  );
};

SidebarView.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  filters: PropTypes.shape({}).isRequired,
  setSelectedConversation: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  selectedConversation: PropTypes.shape({
    hashId: PropTypes.string,
    twilioId: PropTypes.string,
  }).isRequired,
  conversations: PropTypes.arrayOf(PropTypes.shape({
    node: PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      receivedMessages: PropTypes.shape({
        node: PropTypes.shape({
          body: PropTypes.string,
          conversationHash: PropTypes.string,
        }),
      }),
    }),
  })).isRequired,
};

export default SidebarView;
