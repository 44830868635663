import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AttachFile from '@mui/icons-material/AttachFile';
import SendTwoTone from '@mui/icons-material/SendTwoTone';
import Close from '@mui/icons-material/Close';
import { SEND_MESSAGE } from '@fingo/lib/graphql';
// import WhatsappRecorder from './WhatsappRecorder';

const BottomBarContent = ({ twilioSenderId, conversationHashId }) => {
  const [file, setFile] = useState(null);
  const [body, setBody] = useState('');

  const [sendMutation, { loading }] = useMutation(SEND_MESSAGE, {
    variables: { conversationHashId, twilioSenderId, body, file },
    onCompleted: () => {
      setBody('');
      setFile(null);
    },
  });

  const tooltipComponent = useMemo(() => (
    <Typography>
      Formatos permitidos para el envío de archivos:
      .xlsx, .pdf, .mp4,.png, .jpeg, .pdf, .docx, .jpg
    </Typography>
  ), []);

  return (
    <Box p={1.5}>
      <Stack direction="row" spacing={1} alignItems="center">
        {file && (
          <Close sx={{ width: '15px', cursor: 'pointer' }} onClick={() => setFile(null)} />
        )}
        <Tooltip title={tooltipComponent}>
          <label htmlFor="whatsapp-file">
            <input
              id="whatsapp-file"
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
              accept=".xlsx,.pdf,.mp4,.png,.jpeg,.pdf,.docx,.jpg"
              style={{ display: 'none' }}
            />
            <IconButton aria-label="attach" component="span">
              <AttachFile sx={{ color: file ? 'primary.main' : 'gray.main' }} />
            </IconButton>
          </label>
        </Tooltip>
        <InputBase
          placeholder="Escribe tu mensaje aquí..."
          value={body}
          onChange={({ target }) => setBody(target.value)}
          onKeyDown={(event) => {
            if (body.trim() && event.key === 'Enter' && !event.shiftKey) {
              event.preventDefault();
              sendMutation();
            } else if (!event.shiftKey && event.key === 'Enter') {
              event.preventDefault();
            }
          }}
          sx={{ fontSize: 18 }}
          autoFocus
          fullWidth
        />
        <LoadingButton
          startIcon={<SendTwoTone />}
          variant="contained"
          size="small"
          loading={loading}
          onClick={sendMutation}
          disabled={!body && !file}
        >
          Enviar
        </LoadingButton>
        {/* // twilio fix pending. audio files doesnt fetch into user whatsapp */}
        {/* <WhatsappRecorder sendMessage={sendMessage} setFile={setFile} /> */}
      </Stack>
    </Box>
  );
};

BottomBarContent.propTypes = {
  twilioSenderId: PropTypes.string.isRequired,
  conversationHashId: PropTypes.string.isRequired,
};

export default BottomBarContent;
