import React from 'react';
import { formatRut } from '@fingo/lib/helpers';
import TextCell from '@fingo/lib/components/cells/TextCell';
import { CompanyProfileCell } from '@fingo/lib/components/cells';

export const ROWS_PER_PAGE = 15;
export const HEADER_TITLE = 'Leads';
export const DATA_GRID_COLUMNS = [
  {
    field: 'masterEntity_Name',
    headerName: 'Empresa',
    renderCell: ({ row }) => <CompanyProfileCell masterEntity={row.masterEntity} />,
    flex: 2,
  },
  {
    field: 'source_Name',
    headerName: 'Tipo de negocio',
    renderCell: ({ row }) => <TextCell text={row?.source?.name} />,
    flex: 1,
  },
  {
    field: 'type_Name',
    headerName: 'Tipo de cliente',
    sortable: false,
    renderCell: ({ row }) => <TextCell text={row?.type?.name} />,
    flex: 1,
  },
];

export const FORM_DATA_GRID_COLUMNS = [
  {
    field: 'masterEntityName',
    type: 'string',
    headerName: 'Razón social',
    align: 'center',
    editable: true,
    flex: 2,
  },
  {
    field: 'masterEntityRut',
    type: 'string',
    headerName: 'RUT',
    align: 'center',
    editable: true,
    valueFormatter: ({ value }) => formatRut(value),
    flex: 1,
  },
  {
    field: 'sourceName',
    type: 'string',
    headerName: 'Tipo de negocio',
    align: 'center',
    editable: true,
    flex: 1,
  },
  {
    field: 'typeName',
    type: 'singleSelect',
    headerName: 'Tipo de cliente',
    align: 'center',
    valueOptions: ['Nuevo', 'Cartera'],
    editable: true,
    flex: 1,
  },
];
