import Box from '@mui/material/Box';
import React from 'react';
import PropTypes from 'prop-types';
import AttachFile from '@mui/icons-material/AttachFile';
import WhatsappAudioPlayer from './WhatsappAudioPlayer';
import WhatsappImageMessage from './WhatsappImageMessage';
import WhatsappVideoMessage from './WhatsappVideoMessage';

const FileWhatsappMessage = ({ whatsapplogfileSet }) => {
  if (!whatsapplogfileSet.length) return null;
  const file = whatsapplogfileSet[0];
  if (file.contentType.includes('audio')) return <WhatsappAudioPlayer file={file} />;
  if (file.contentType.includes('video')) return <WhatsappVideoMessage file={file} />;
  if (file.contentType.includes('image')) return <WhatsappImageMessage file={file} />;
  return (
    <MediaBox name={file.file.name} url={file.file.url}>
      <AttachFile /> Archivo adjunto
    </MediaBox>
  );
};

const MediaBox = ({ name, url, ...props }) => (
  <Box
    display="flex"
    alignItems="flex-start"
    justifyContent="flex-start"
    alt={name}
    boxShadow={1}
    p={1}
    onClick={() => window.open(url)}
    sx={{
      width: { xs: '50px', md: '300px' },
      alignSelf: 'center',
      cursor: 'pointer',
      alignItems: 'center',
    }}
    {...props}
  />
);

MediaBox.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

FileWhatsappMessage.propTypes = {
  whatsapplogfileSet: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    contentType: PropTypes.string.isRequired,
    file: PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string,
    }),
  })),
};

FileWhatsappMessage.defaultProps = {
  whatsapplogfileSet: [],
};

export default FileWhatsappMessage;
